import React from "react"
import {graphql, Link} from "gatsby"
import useSiteTreeContext from "silverstripe-gatsby-helpers/lib/hooks/useSiteTreeContext"
import Layout from "../Page"
import SEOTags from "../../components/SEOTags"
import Breadcrumbs from "../../components/Breadcrumbs"

const Page = ({data: {silverStripeDataObject, allSilverStripeDataObject}}) => {
    const {title} = silverStripeDataObject.SilverStripeSiteTree;
    const  {getChildren, isLevel}  = useSiteTreeContext();
    const children = getChildren();
    const isLevel2 = isLevel(2);
    const hasSubnav = isLevel(2) || !!children.length;

    const blogposts = allSilverStripeDataObject.edges;

    console.log('blogposts:');
    console.log(blogposts);

    return (
        <Layout>
            <SEOTags pageTitle={title}/>

            <div className={`content ${hasSubnav ? 'hasSidebar' : ''}`}>
                <div className="main">
                    {isLevel2 &&
                    <Breadcrumbs/>
                    }
                    <h1 className="font-bold text-2xl mb-4">{title}</h1>

                    <section>
                        {blogposts.map(child => {
                            const summary = child.node.SilverStripeBlogPost.summary;
                            const isExternal = child.node.SilverStripeBlogPost.type === 'external';
                            const link = isExternal
                                ? <a href={child.node.SilverStripeBlogPost.external} target={'_blank'}>{child.node.SilverStripeSiteTree.title}</a>
                                : <Link to={child.node.link}>{child.node.SilverStripeSiteTree.title}</Link>;

                            return <div className={'my-8'}>
                                <h2 className={'font-bold'}>{link}</h2>
                                {summary && <p>{summary}</p>}
                            </div>
                        })}
                    </section>
                </div>


            </div>
        </Layout>
    );
};

export default Page

export const pageQuery = graphql`
    query ($link: String!) {
        silverStripeDataObject(link: {eq: $link}) {
            silverstripe_id
            SilverStripeSiteTree {
                title
            }
        }
        allSilverStripeDataObject(filter: {className: {eq: "SilverStripe__Blog__Model__BlogPost"}}, sort: {fields: [SilverStripeBlogPost___publishDate, created], order: DESC}) {
            edges {
                node {
                    SilverStripeSiteTree {
                        title
                    }
                    className
                    link
                    SilverStripeBlogPost {
                        Parent {
                            silverstripe_id
                        }
                        summary
                        publishDate(formatString: "DD.MM.Y")
                        type
                        external
                    }
                }
            }
        }
    }

`;